import React, { useState } from "react"
import { NavLink, Outlet } from "react-router-dom";
import { MdClose } from "react-icons/md";
import { FiMenu } from "react-icons/fi";


const Navbar = () => {
    const links = [
        {
            id: 1,
            path: "/",
            text: "Home",
        },
        {
            id: 2,
            path: "/about",
            text: "About",
        },
    ];

    const [navbarOpen, setNavbarOpen] = useState(false);

    const handleToggle = () => {
        setNavbarOpen( prev => !prev );
    };

    const closeMenu = () => {
        setNavbarOpen(false);
    };

    return (
        <>        
            <nav className="navBar">
                <ul className={`menuNav ${navbarOpen ? " showMenu" : ""}`}>
                    <button onClick={handleToggle}>
                    {navbarOpen 
                        ? ( <MdClose style={{ color: "#fff", width: "40px", height: "40px" }} /> ) 
                        : ( <FiMenu style={{ color: "#7b7b7b", width: "40px", height: "40px" }} /> )}
                    </button>

                    <li>
                        {links.map( link => (
                            <NavLink              
                                style={({ isActive }) => {
                                    return {
                                        color: isActive ? "orangered" : "",
                                        textDecorationLine: isActive ? "underline" : "none",
                                    };
                                }}
                                key={link.id}
                                to= {link.path}
                                onClick={() => closeMenu()}
                            >
                                {link.text}
                            </NavLink>
                        ))}
                    </li>
                </ul>
            </nav>
            <Outlet />
        </>

        )
}
export default Navbar